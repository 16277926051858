var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"right":"","max-width":"265px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.removeAllEmails)?_c('span',_vm._g({staticClass:"clear-button cursor-pointer"},on),[_vm._v("Limpar lista")]):_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"icon":""}},on),[_c('mf-icon',{attrs:{"size":"20","color":"cinza-0"}},[_vm._v("delete")])],1)]}}]),model:{value:(_vm.openRemoveEmail),callback:function ($$v) {_vm.openRemoveEmail=$$v},expression:"openRemoveEmail"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-title font-weight-bold pt-4"},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('v-card-text',[_c('p',{staticClass:"mb-0 mt-3 px-1 texto-corrido"},[_vm._v(_vm._s(_vm.cardText))]),_c('mf-action-buttons',{attrs:{"primary-button":{
          text: 'Excluir',
          action: _vm.deleteEmail,
          isVisible: true
        },"secondary-button":{
          text: 'Cancelar',
          action: function () { return (_vm.openRemoveEmail = false); },
          isVisible: true
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }