var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"email-title"},[_vm._v("Incluir e-mails")]),_c('div',{staticClass:"inputs-container d-flex align-start justify-space-between mb-2"},[_c('v-text-field',{attrs:{"hide-details":!_vm.errorEmail,"dense":"","error-messages":_vm.errorEmail,"placeholder":"E-mail","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addEmail($event)}},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('v-btn',{attrs:{"outlined":"","height":"40","color":"primary"},on:{"click":_vm.addEmail}},[_vm._v(" Adicionar ")])],1),_c('div',{staticClass:"d-flex justify-end align-center px-1 py-2"},[_c('mf-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("info")]),_c('p',{staticClass:"ma-0 texto-corrido"},[_vm._v("Alterações realizadas na lista abaixo impacta apenas esse envio.")])],1),_c('v-data-table',{attrs:{"fixed-header":"","disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"height":_vm.computedEmails.length > _vm.maxEmailsToScroll ? _vm.scrollHeight : '',"items":_vm.computedEmails,"no-data-text":"Nenhum e-mail adicionado"},scopedSlots:_vm._u([{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v("E-mail")])]}},{key:"header.clear",fn:function(ref){
var header = ref.header;
return [(_vm.emails.length > 0)?_c('delete-email',{attrs:{"remove-all-emails":""},on:{"remove-email":_vm.clearList}}):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item))]),_c('td',{staticClass:"text-right"},[_c('delete-email',{on:{"remove-email":function($event){return _vm.removeEmail(index)}}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }