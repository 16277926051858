<template>
  <v-menu v-model="openRemoveEmail" :close-on-content-click="false" right max-width="265px">
    <template v-slot:activator="{ on }">
      <span v-if="removeAllEmails" class="clear-button cursor-pointer" v-on="on">Limpar lista</span>
      <v-btn v-else icon class="ma-0" v-on="on">
        <mf-icon size="20" color="cinza-0">delete</mf-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="card-title font-weight-bold pt-4"> {{ cardTitle }} </v-card-title>
      <v-card-text>
        <p class="mb-0 mt-3 px-1 texto-corrido">{{ cardText }}</p>
        <mf-action-buttons
          :primary-button="{
            text: 'Excluir',
            action: deleteEmail,
            isVisible: true
          }"
          :secondary-button="{
            text: 'Cancelar',
            action: () => (openRemoveEmail = false),
            isVisible: true
          }"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'DeleteEmail',
  props: {
    removeAllEmails: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    openRemoveEmail: false
  }),
  computed: {
    cardTitle() {
      return this.removeAllEmails ? 'Excluir todos os e-mails' : 'Excluir e-mail'
    },
    cardText() {
      return this.removeAllEmails
        ? 'Tem certeza que deseja excluir todos os e-mails da lista?'
        : 'Tem certeza que deseja excluir esse e-mail da lista de envio?'
    }
  },
  methods: {
    deleteEmail() {
      this.openRemoveEmail = false
      this.$emit('remove-email')
    },
    closeDeleteEmail() {
      this.openRemoveEmail = false
    }
  }
}
</script>
<style scoped lang="scss">
.clear-button {
  text-align: right !important;
  color: #b3280d !important;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
</style>
